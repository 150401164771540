import {jQueryScrollSpot} from './jquery.scrollSpot';

jQueryScrollSpot();

/**
 * Global plugin options object
 *
 * @type {*|{ajax_url: string}}
 *
 * @since 1.0.0
 */
window.DaReactions = {
    ...{
        ajax_url: '',
        display_detail_modal: 'off',
        display_detail_modal_toolbar: 'off',
        display_detail_tooltip: 'off',
        display_name: '',
        emotion_id: 0,
        labels: {
            modal_no_tabs_title: 'Reactions',
            modal_tab_all_title: 'All reactions',
            modal_pagination_close: '×',
            modal_pagination_next: '→',
            modal_pagination_prev: '←',
            modal_pagination_desc: 'Page %d of %d'
        },
        loader_url: '',
        modal_result_limit: 0,
        nonce: '',
        pagination: {
            index: 0,
            total: 0,
            size: 0
        },
        reactions: [],
        show_count: '',
        tooltip_result_limit: 0,
        user_link: ''
    }, ...DaReactions
};

(function ($) {

    /**
     * Callback for click on reaction image to save reaction
     *
     * @since 1.0.0
     */
    function clickedReaction() {
        // gets all parameters
        /*
         <div class="da-reactions-outer TpostID1">
            <div
                class="da-reactions-data da-reactions-container-async center in-viewport spotted"
                data-type="post"
                data-id="1"
                data-nonce="f12a76f5f5"
                id="da-reactions-slot-post-1">

			<div class="da-reactions-reveal center">
				<div class="before-reveal">
					<img src="http://wordpress.loc/wp-content/uploads/da-reactions/love-2.svg?_=1606675425" alt="Add your reaction" width="64" height="64" style="width:64px; height:64px">
					<div class="count" data-value="1"></div>
				</div>
				<div class="after-reveal da-reactions-container has_current">

				<div class="da-reactions-data reaction reaction_1 inactive" data-id="1" data-nonce="f12a76f5f5" data-reaction="1" data-title="Like" data-type="post">
					<img src="http://wordpress.loc/wp-content/uploads/da-reactions/like-1.svg?_=1606675425" alt="Like" width="64" height="64" style="width:64px; height:64px">
					<div class="count" data-value="0"></div>
				</div>

				<div class="da-reactions-data reaction reaction_2 inactive" data-id="1" data-nonce="f12a76f5f5" data-reaction="2" data-title="Love" data-type="post">
					<img src="http://wordpress.loc/wp-content/uploads/da-reactions/love-2.svg?_=1606675425" alt="Love" width="64" height="64" style="width:64px; height:64px">
					<div class="count" data-value="0"></div>
				</div>

				<div class="da-reactions-data reaction reaction_3 inactive" data-id="1" data-nonce="f12a76f5f5" data-reaction="3" data-title="Ah Ah" data-type="post">
					<img src="http://wordpress.loc/wp-content/uploads/da-reactions/ah-ah-3.svg?_=1606675425" alt="Ah Ah" width="64" height="64" style="width:64px; height:64px">
					<div class="count" data-value="0"></div>
				</div>

				<div class="da-reactions-data reaction reaction_4 inactive" data-id="1" data-nonce="f12a76f5f5" data-reaction="4" data-title="Wow" data-type="post">
					<img src="http://wordpress.loc/wp-content/uploads/da-reactions/wow-4.svg?_=1606675425" alt="Wow" width="64" height="64" style="width:64px; height:64px">
					<div class="count" data-value="0"></div>
				</div>

				<div class="da-reactions-data reaction reaction_5 inactive" data-id="1" data-nonce="f12a76f5f5" data-reaction="5" data-title="Sad" data-type="post">
					<img src="http://wordpress.loc/wp-content/uploads/da-reactions/sad-5.svg?_=1606675425" alt="Sad" width="64" height="64" style="width:64px; height:64px">
					<div class="count" data-value="0"></div>
				</div>

				<div class="da-reactions-data reaction reaction_6 inactive" data-id="1" data-nonce="f12a76f5f5" data-reaction="6" data-title="Grrr" data-type="post">
					<img src="http://wordpress.loc/wp-content/uploads/da-reactions/grrr-6.svg?_=1606675425" alt="Grrr" width="64" height="64" style="width:64px; height:64px">
					<div class="count" data-value="0"></div>
				</div>

				</div>
			</div>
		</div></div>
         */
        let me = $(this).closest('.da-reactions-data'),
            my_type = $(me).data('type'),
            my_id = $(me).data('id'),
            my_reaction = $(me).data('reaction'),
            my_nonce = $(me).data('nonce') || DaReactions.nonce,
            clicked_image = $(me).find('img'),
            siblings = $('.da-reactions-outer.T' + my_type + 'ID' + my_id),
            outer_container = $(me).closest('.da-reactions-outer'),
            before_reveal_container = $(outer_container).find('.before-reveal'),
            target_image = before_reveal_container.find('img'),
            target_total_count = before_reveal_container.find('.count');

        const current_image_src = target_image.attr('src');
        target_image.attr('src', DaReactions.loader_url);

        removeFromCache('clickedCount', my_type, my_id);
        removeFromCache('mouseOverCount', my_type, my_id);

        // calls ajax service
        $.ajax({
            url: DaReactions.ajax_url,
            method: 'post',
            cache: false,
            data: {
                'action': 'da_reactions_add_reaction',
                'id': my_id,
                'nonce': my_nonce,
                'reaction': my_reaction,
                'type': my_type
            },
            success: function (result) {
                if (result.success === 'ok') {
                    removeFromCache('mouseOverCount', my_type, my_id)
                    // update classes and labels
                    $(siblings).each(function () {
                        let before_reveal_container = $(this).find('.before-reveal');
                        let target_image = before_reveal_container.find('img');
                        let foundCurrent = false;
                        let totalScore = 0;
                        if (target_image.length) {
                            target_image.attr('src', clicked_image.attr('src'));
                        }
                        let showCount = DaReactions.show_count;
                        if ($('select#show_count').length) {
                            showCount = $('select#show_count').val();
                        }
                        const showCountBeforeReveal = {
                            'always': 'always',
                            'percentage': 'always',
                            'non-zero': 'non-zero',
                            'percentage-non-zero': 'non-zero',
                            'never': 'never'
                        }[showCount] || 'never';
                        for (let a in result['reactions']) {
                            if (result['reactions'].hasOwnProperty(a)) {
                                let $ico = $(this).find('.reaction.reaction_' + result['reactions'][a]['ID']);
                                if (result['reactions'][a]['current']) {
                                    foundCurrent = true;
                                    $ico.addClass('active');
                                    $ico.removeClass('inactive');
                                } else {
                                    $ico.removeClass('active');
                                    $ico.addClass('inactive');
                                }
                                const reactionCount = result['reactions'][a]['total'] || 0;
                                const reactionPercentage = Math.round(+result['reactions'][a]['percentage'] || 0) + '%';
                                const $countBadge = $ico.find('.count');

                                switch (showCount) {
                                    case 'always':
                                        $countBadge.attr('data-value', numberFormatter(reactionCount));
                                        $countBadge.show();
                                        break;
                                    case 'percentage':
                                        $countBadge.attr('data-value', reactionPercentage);
                                        $countBadge.show();
                                        break;
                                    case 'non-zero':
                                        $countBadge.attr('data-value', numberFormatter(reactionCount));
                                        if (reactionCount > 0) {
                                            $countBadge.show();
                                        } else {
                                            $countBadge.hide();
                                        }
                                        break;
                                    case 'percentage-non-zero':
                                        if (reactionPercentage !== '0%') {
                                            $countBadge.show();
                                        } else {
                                            $countBadge.hide();
                                        }
                                        $countBadge.attr('data-value', reactionPercentage);
                                        break;
                                    case 'never':
                                        $countBadge.hide();
                                        break;
                                }
                                totalScore += +result['reactions'][a]['total'];
                            }
                        }
                        switch (showCountBeforeReveal) {
                            case 'always':
                                target_total_count.attr('data-value', numberFormatter(totalScore));
                                target_total_count.show();
                                break;
                            case 'non-zero':
                                if (String(totalScore)[0] === '0') {
                                    target_total_count.hide();
                                } else {
                                    target_total_count.attr('data-value', numberFormatter(totalScore));
                                }
                                break;
                            case 'never':
                                target_total_count.hide();
                                break;
                        }
                        if (foundCurrent) {
                            $(this).closest('.da-reactions-container').addClass('has_current');
                        } else {
                            $(this).closest('.da-reactions-container').removeClass('has_current');
                        }
                    });
                } else {
                    alertWindow(result.message);
                }
            },
            error: () => {
                target_image.attr('src', current_image_src);
            }
        });
    }

    /**
     * Toggle visibility of widget on small screen devices
     *
     * @param event
     *
     * @since 2.0.4
     */
    function clickedToggle(event) {
        event.stopPropagation();
        $(this).parent().toggleClass('open');
    }

    /**
     * Callback for click on count badge, opens details modal
     *
     * @since 3.0.0
     */
    function clickedCount(e) {
        const me = e.target;
        e.preventDefault();
        removeUserTooltip();
        let my_container = $(me).closest('.da-reactions-data, .da-reactions-container-async.gutenberg-block'),
            my_type = $(my_container).data('type'),
            my_id = $(my_container).data('id'),
            my_nonce = $(my_container).data('nonce') || DaReactions.nonce,
            my_reaction = $(me).data('reaction') || $(my_container).data('reaction') || 0,
            pageNum = $(me).data('pageNum') || 0,
            result = loadFromCache('clickedCount', my_type, my_id, my_reaction, pageNum);
        let data = {
            'action': 'da_reactions_get_users_reactions',
            'type': my_type,
            'id': my_id,
            'nonce': my_nonce,
            'reaction': my_reaction,
            'pageNum': pageNum,
            'limit': parseInt(DaReactions.modal_result_limit, 10)
        };
        if (!result || result.success !== 'ok') {
            $.ajax({
                url: DaReactions.ajax_url,
                method: 'post',
                cache: false,
                data,
                success: function (result) {
                    saveToCache('clickedCount', my_type, my_id, my_reaction, result, pageNum);
                    if (result.success && result.success === 'ok') {
                        showUsersModal(result, data);
                    }
                }
            });
        } else {
            showUsersModal(result, data);
        }
    }

    /**
     *
     * @param prefix
     * @param my_type
     * @param my_id
     * @param my_reaction
     * @param page
     */
    function initDaReactionsCache(prefix, my_type, my_id, my_reaction, page) {
        DaReactions.cache = DaReactions.cache || {};
        DaReactions.cache[prefix] = DaReactions.cache[prefix] || {};
        DaReactions.cache[prefix][my_type] = DaReactions.cache[prefix][my_type] || {};
        DaReactions.cache[prefix][my_type][my_id] = DaReactions.cache[prefix][my_type][my_id] || {};
        DaReactions.cache[prefix][my_type][my_id][my_reaction] = DaReactions.cache[prefix][my_type][my_id][my_reaction] || {};
        DaReactions.cache[prefix][my_type][my_id][my_reaction][page] = DaReactions.cache[prefix][my_type][my_id][my_reaction][page] || {};
    }

    /**
     * Retrieve cached object to avoid redundant server requests
     *
     * @param prefix
     * @param my_type
     * @param my_id
     * @param my_reaction
     * @param page
     * @returns {{success: string}|*}
     *
     * @since 3.0.0
     */
    function loadFromCache(prefix, my_type, my_id, my_reaction, page) {
        initDaReactionsCache(prefix, my_type, my_id, my_reaction, page);

        if (DaReactions.cache[prefix][my_type][my_id][my_reaction][page].hasOwnProperty('success') && DaReactions.cache[prefix][my_type][my_id][my_reaction][page].success === 'ok') {
            return DaReactions.cache[prefix][my_type][my_id][my_reaction][page];
        }

        return {
            success: 'no'
        };
    }

    /**
     * Remove cached value
     *
     * @param prefix
     * @param my_type
     * @param my_id
     * @param my_reaction
     *
     * @since 3.0.0
     */
    function removeFromCache(prefix = '', my_type = '', my_id = 0, my_reaction = 0) {
        initDaReactionsCache(prefix, my_type, my_id, my_reaction, 0);
        if (!!prefix) {
            if (!!my_type) {
                if (!!my_id) {
                    if (!!my_reaction) {
                        delete DaReactions.cache[prefix][my_type][my_id][my_reaction];
                    } else {
                        delete DaReactions.cache[prefix][my_type][my_id];
                    }
                } else {
                    delete DaReactions.cache[prefix][my_type];
                }
            } else {
                delete DaReactions.cache[prefix];
            }
        }
    }

    /**
     * Save value on local cache
     *
     * @param prefix
     * @param my_type
     * @param my_id
     * @param my_reaction
     * @param value
     * @param page
     * @since 3.0.0
     */
    function saveToCache(prefix, my_type, my_id, my_reaction, value, page) {
        initDaReactionsCache(prefix, my_type, my_id, my_reaction, page);
        DaReactions.cache[prefix][my_type][my_id][my_reaction][page] = value;
    }

    /**
     * Display a modal popup with users and reactions list
     *
     * @param userReactions
     * @param requestData
     *
     * @since 3.0.0
     */
    function showUsersModal(userReactions, requestData) {
        removeUsersModal();
        if (!userReactions.reactions.length) {
            return;
        }
        let $modal_background = $('<div>')
            .attr('id', 'da-reactions-users-modal-background');
        let $modal = $('<div>')
            .addClass('da-reactions-data')
            .data('type', requestData.type)
            .data('id', requestData.id)
            .data('limit', requestData.limit)
            .data('nonce', requestData.nonce)
            .data('reaction', requestData.reaction);
        let activeEmotions = {}

        $modal_background.append($modal);

        const $scrollContainer = $('<div>')
            .addClass('scroll-container');
        $modal.append($scrollContainer);

        for (const userReaction of userReactions.reactions) {
            activeEmotions[userReaction.emotion_id] = {
                image: userReaction.image,
                label: userReaction.label
            }
            const $row = $('<a>')
                .attr('href', userReaction.user_link || 'javascript:;')
                .addClass('da-reactions-users-modal-row')
                .addClass('list')
                .addClass('reaction' + userReaction.emotion_id);
            const $rowImage = $(`<img src="${userReaction.image}" alt="${userReaction.label}">`);
            $row.append($rowImage);
            const $rowLabel = $('<span>')
                .text(userReaction.display_name);
            $row.append($rowLabel);
            $scrollContainer.append($row);
        }

        if (Array.isArray(userReactions.buttons)) {
            const $tools = $('<div>')
                .addClass('da-reactions-users-modal-row')
                .addClass('tools');


            const $emptyButton = $('<div>')
                .addClass('da-reactions-button')
                .addClass('da-reactions-users-modal-toggle')
                .data('reaction', '0');
            if (DaReactions.display_detail_modal_toolbar === 'on') {
                $emptyButton.html(DaReactions.labels.modal_tab_all_title);
            } else {
                $emptyButton.html(DaReactions.labels.modal_no_tabs_title);
            }
            $emptyButton.on('click', clickedCount);
            $tools.append($emptyButton);

            let foundCurrent = false;

            if (DaReactions.display_detail_modal_toolbar === 'on') {
                for (const tool of userReactions.buttons) {
                    const $toolButton = $('<div>')
                        .addClass('da-reactions-button')
                        .addClass('da-reactions-users-modal-toggle')
                        .addClass('da-reactions-toggle-reaction' + tool.ID)
                        .data('reaction', tool.ID);
                    const $toolImage = $(`<img src="${tool.image}" alt="${tool.label}">`)
                        .data('reaction', tool.ID);
                    $toolButton.append($toolImage);
                    if (tool.current) {
                        $toolButton.addClass('current');
                        foundCurrent = true;
                    } else {
                        $toolButton.on('click', clickedCount);
                    }
                    $tools.append($toolButton);
                }
            }

            if (!foundCurrent) {
                $emptyButton.addClass('current');
            }
            $modal.prepend($tools);
        }

        let pageNum = parseInt(userReactions.pagination.index, 10);
        let pageCount = Math.ceil(userReactions.pagination.total / userReactions.pagination.size);

        let $paginator = $('<div>')
            .addClass('da-reactions-users-modal-paginator da-reactions-data')
            .data('type', requestData.type)
            .data('id', requestData.id)
            .data('limit', requestData.limit)
            .data('nonce', requestData.nonce)
            .data('reaction', requestData.reaction);

        if (pageNum > 1) {
            const $prevButton = $('<span>')
                .addClass('da-reactions-users-modal-paginator-back')
                .html(DaReactions.labels.modal_pagination_prev)
                .data('pageNum', pageNum - 1);
            $paginator.append($prevButton);
            $prevButton.on('click', clickedCount);
        }
        const $currentPage = $('<span>')
            .addClass('da-reactions-users-modal-paginator-text')
            .text(
                DaReactions
                    .labels
                    .modal_pagination_desc
                    .replace('{current}', String(pageNum))
                    .replace('{total}', String(pageCount))
            );
        $paginator.append($currentPage);
        if (pageNum < pageCount) {
            const $nextButton = $('<span>')
                .addClass('da-reactions-users-modal-paginator-next')
                .html('&rarr;')
                .data('pageNum', pageNum + 1);
            $paginator.append($nextButton)
            $nextButton.on('click', clickedCount);
        }

        $paginator.append($('<span>').addClass('spacer'));

        const $closeButton = $('<span>')
            .addClass('da-reactions-users-modal-paginator-close')
            .text(DaReactions.labels.modal_pagination_close)
            .on('click', removeUsersModal);
        $paginator.append($closeButton);

        $modal.append($paginator);

        $('body').append($modal_background);
    }

    /**
     * Remove modal popup created with showUsersModal
     *
     * @since 3.0.0
     */
    function removeUsersModal() {
        $('#da-reactions-users-modal-background').remove();
    }

    /**
     * Display a tooltip with latest user’s reactions
     *
     * @param userReactions
     * @param mouseEvent
     *
     * @since 3.0.0
     */
    function showUserTooltip(userReactions, mouseEvent) {
        removeUserTooltip();
        if (!userReactions.length) {
            return;
        }
        let $tooltip = $('<div id="da-reactions-users-tooltip"></div>')
            .css({left: mouseEvent.clientX, top: mouseEvent.clientY - 20});
        let $tooltip_inner = $('<div>');
        $tooltip.append($tooltip_inner);
        let count = 0;
        for (let userReaction of userReactions) {
            count++;
            let $row = $('<div class="da-reactions-users-tooltip-row"></div>');
            $row.append($(`<img src="${userReaction.image}" alt="${userReaction.label}">`));
            $row.append($(`<span>${userReaction.display_name}</span>`));
            $tooltip_inner.append($row);
        }
        $('body').append($tooltip);
    }

    /**
     * Change coordinates of tooltip created by showUserTooltip to follow mouse
     *
     * @param mouseEvent
     *
     * @since 3.0.0
     */
    function moveUserTooltip(mouseEvent) {
        $('#da-reactions-users-tooltip')
            .css({left: mouseEvent.clientX, top: mouseEvent.clientY - 20});
    }

    /**
     * Remove tooltip created by showUserTooltip
     *
     * @since 3.0.0
     */
    function removeUserTooltip() {
        $('#da-reactions-users-tooltip').html('').remove();
    }

    /**
     * Callback for mouseover on count badge
     *
     * @param event
     *
     * @since 3.0.0
     */
    function mouseOverCounter(event) {
        let my_container = $(this).closest('.da-reactions-data, .da-reactions-container-async.gutenberg-block'),
            my_type = $(my_container).data('type'),
            my_id = $(my_container).data('id'),
            my_nonce = $(my_container).data('nonce') || DaReactions.nonce,
            my_reaction = $(my_container).data('reaction') || '',
            result = loadFromCache('mouseOverCount', my_type, my_id, my_reaction, 1),
            data = {
                'action': 'da_reactions_get_users_reactions',
                'type': my_type,
                'id': my_id,
                'nonce': my_nonce,
                'reaction': my_reaction,
                'limit': parseInt(DaReactions.tooltip_result_limit, 10)
            };

        if (!result || result.success !== 'ok') {
            $.ajax({
                url: DaReactions.ajax_url,
                method: 'post',
                cache: false,
                data,
                success: function (result) {
                    if (result.success === 'ok') {
                        saveToCache('mouseOverCount', my_type, my_id, my_reaction, result, 1);
                        showUserTooltip(result.reactions, event);
                    }
                }
            });
        } else if (result.success === 'ok') {
            showUserTooltip(result.reactions, event);
        }
    }

    /**
     * Callback for mousemove on count badge
     *
     * @param event
     *
     * @since 3.0.0
     */
    function mouseMoveCounter(event) {
        if ($(event.target).is('.count')) {
            moveUserTooltip(event);
        } else {
            removeUserTooltip();
        }
    }

    /**
     * Callback for mouseout from count badge
     *
     * @since 3.0.0
     */
    function mouseOutCounter() {
        removeUserTooltip();
    }

    /**
     * Loads reactions on placeholder
     */
    function loadReactions() {
        if (!$(this).data('spotted')) {
            $.ajax({
                context: this,
                cache: false,
                data: {
                    action: 'da_reactions_load_buttons',
                    type: $(this).data('type'),
                    id: $(this).data('id'),
                    nonce: $(this).data('nonce') || DaReactions.nonce
                },
                method: 'post',
                success: function (result) {
                    $(this).html(result);
                },
                url: DaReactions.ajax_url
            })
        }
    }

    /**
     * Utility function to format big numbers
     *
     * @param number
     * @returns {string}
     *
     * @since 1.0.0
     */
    function numberFormatter(number) {
        number = (typeof number !== 'undefined') ? +number : 0;
        let si = [
            {value: 1, symbol: ""},
            {value: 1E3, symbol: "K"},
            {value: 1E6, symbol: "M"},
            {value: 1E9, symbol: "G"},
            {value: 1E12, symbol: "T"},
            {value: 1E15, symbol: "P"},
            {value: 1E18, symbol: "E"},
            {value: 1E21, symbol: "Z"},
            {value: 1E23, symbol: "Y"}
        ];
        let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (number >= si[i].value) {
                break;
            }
        }
        return (number / si[i].value).toFixed(2).replace(rx, "$1").replace('.00', '') + si[i].symbol;
    }

    function alertWindow(message) {
        const $background = $('<div>')
            .attr('id', 'da-reactions-users-modal-background');

        const $window = $('<div>')
            .addClass('da-reactions-window');

        const $paragraph = $('<p>')
            .addClass('da-reactions-window-paragraph')
            .text(message);

        const $spacer = $('<div>')
            .addClass('spacer');

        const $closeButton = $('<span>')
            .addClass('da-reactions-window-close')
            .text(DaReactions.labels.modal_pagination_close)
            .on('click', removeUsersModal);

        $window.append($paragraph);
        $window.append($spacer);
        $window.append($closeButton);

        $background.append($window);

        $('body').prepend($background);
    }

    /**
     * All actions are down here
     *
     * @since 1.0.0
     */
    $(document).ready(function () {

        let $daReactionsDocument = $(document);

        /**
         * Enables Async load of reactions
         *
         * @since 1.0.0
         */
        $daReactionsDocument.scrollSpot(loadReactions, ".da-reactions-container-async");

        /**
         * Enables click on reaction button through generic selector
         *
         * @since 2.0.4
         */
        $daReactionsDocument.on('click', '.reaction img', clickedReaction);

        /**
         * Enables click on reaction count badge though .da-reactions-outer selector
         *
         * @since 3.0.0
         */
        if (DaReactions.display_detail_modal === 'on') {
            $daReactionsDocument.on('click', '.da-reactions-outer .count', clickedCount);
        }

        /**
         * Enables click on outer count badge for “reveal” template
         *
         * @since 3.0.0
         */
        if (DaReactions.display_detail_modal === 'on') {
            $daReactionsDocument.on('click', '.da-reactions-outer .count', clickedCount);
        }

        /**
         * Enables click on mobile toggle hamburger
         *
         * @since 2.0.4
         */
        $daReactionsDocument.on('click', '.reactions-toggle', clickedToggle);

        /**
         * Enables click on image to open widget in “reveal” template
         *
         * @since 3.0.0
         */
        $daReactionsDocument.on('click', '.da-reactions-reveal img', function () {
            $(this).closest('.da-reactions-reveal').toggleClass('active');
        });

        /**
         * Close .da-reactions-reveal on click outside
         *
         * @since 2.1.1
         */
        $daReactionsDocument.on('mouseup', function (event) {
            let $currentActive = $('.da-reactions-reveal.active:eq(0)');
            if (!$currentActive.is(event.target) && $currentActive.has(event.target).length === 0) {
                $currentActive.removeClass('active');
            }
        });

        /**
         * Show reaction users on mouseover
         *
         * @since 3.0.0
         */
        if (DaReactions.display_detail_tooltip === 'on') {
            $daReactionsDocument.on('mouseover', '.da-reactions-container .count, .da-reactions-reveal .count', mouseOverCounter);
        }

        /**
         * Move tooltip of users on mouseover
         *
         * @since 3.0.0
         */
        if (DaReactions.display_detail_tooltip === 'on') {
            $daReactionsDocument.on('mousemove', mouseMoveCounter);
        }

        /**
         * Hide reaction users on mouseout
         *
         * @since 3.0.0
         */
        if (DaReactions.display_detail_tooltip === 'on') {
            $daReactionsDocument.on('mouseout', '.da-reactions-outer .count', mouseOutCounter);
        }
    });

})(jQuery);
